<template>
    <div>
        <h1>Material Inventory</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Fields />
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from "@/store/Report.store.js";
    import Fields from "./utils/Fields";
    import Results from "./utils/Results";
    import Options from "./raw_material_inventory_opt";

    export default {
        name: 'raw_material_inventory',
        components: {Results, Fields, Options},
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate: {val: `${new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString()} 00:00`, qsField: '[dateandtimes][rmhi.date_captured_from]'},
                    endDate: {val: `${new Date().toLocaleDateString()} 00:00`, qsField: '[dateandtimes][rmhi.date_captured_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][rmhi.cdi_location_id]'},
                    category: {val: 0, qsField: '[numbers][rm.inventory_category_id]'},
                    materialId: {val: 0, qsField: '[numbers][rmhi.raw_material_id]'},
                    enabledOnly: {val: 'on', qsField: '[isnot][rmhi.disabled]'}
                },
                groupByFields: {},
                dataFields: {
                    raw_material_id: {label: 'Material ID', checked: true},
                    raw_material: {label: 'Material', checked: true},
                    cdi_location: {label: 'Location', checked: true},
                    inventory_category: {label: 'Category', checked: false},
                    date_captured: {label: 'Date', checked: true},
                    current: {label: 'Current #', checked: false},
                    reserved: {label: 'Reserved #', checked: false},
                    total: {label: 'Total #', checked: true},
                    consumed: {label: 'Consumed #', checked: false}
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Material Inventory']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>