<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date</label>
                <!-- TODO add time -->
                <DatePicker v-model="state.optionFields.startDate.val" :input-attributes="{class: 'form-control'}" :pickTime="true" :format="'MM/DD/YYYY hh:mm'"></DatePicker>
            </div>
            <div class="datetime_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date</label>
                <DatePicker v-model="state.optionFields.endDate.val" :input-attributes="{class: 'form-control'}" :pickTime="true" :format="'MM/DD/YYYY hh:mm'"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Category</label>
                <select class="form-control" v-model="state.optionFields.category.val">
                    <option value="0">All</option>
                    <option v-for="[id, materialCategory] in cache.materialCache.MATERIAL_CATEGORIES" v-bind:key="id" :value="id">{{ materialCategory }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Material</label>
                <Typeahead cdi_type="material" v-bind:ID.sync="state.optionFields.materialId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :true-value="'on'" :false-value=0 v-model="state.optionFields.enabledOnly.val"> Enabled Only</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import materialCache from '@/cache/material.cache.js';
    import DatePicker from "../utils/DatePicker";
    import Typeahead from '@/components/utils/typeaheads/Typeahead';

    export default {
        name: 'Options',
        components: {DatePicker, Typeahead},
        data() {
            return {
                state: store.state,
                cache: {
                    cdiLocationCache,
                    materialCache
                }
            }
        }
    }
</script>